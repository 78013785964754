<script>
import Multiselect from "vue-multiselect";
import { Icon } from "@iconify/vue2";

export default {
  props: ["tourRoutes", "editTourProps"],
  components: {
    Multiselect,
    Icon,
  },
  data() {
    return {
      showModal: false,
      editedRouteIndex: null,
      editedProps: [],
      editedRoute: {
        order: "",
        date: "",
        city: "",
        type: "",
        vehicle: "",
        driver: "",
        payment_type: "",
        buy_price: "",
        buy_currency: "",
        sell_price: "",
        sell_currency: "",
        currency: "",
        tour_type: "",
        pay_types: "",
      },
      pay_type: [
        { value: 1, label: "Müşteri Ödemeli" },
        { value: 2, label: "Acenta Ödemeli" },
      ],
      types: [
        { value: 1, label: "Transfer" },
        { value: 2, label: "Tur" },
        { value: 3, label: "Transfer ve Tur" },
        { value: 4, label: "Şehirler Arası Transfer" },
        { value: 5, label: "Şehirler Arası Tur ve Transfer" },
      ],
    };
  },
  mounted() {
    this.editedRoute = this.tourRoutes;
    this.$on(
      "open-edit-modal",
      (tourRoutes, cities, vehicles, currencies, users) => {
        this.showModal = true;
        this.editedRoute = tourRoutes;
        this.editedRoute.cities = cities;
        this.editedRoute.vehicles = vehicles;
        this.editedRoute.users = users;
        this.editedRoute.currencies = currencies;
      }
    );
  },
  methods: {
    formatDate(date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    openModal(index, editedRouteData) {
      this.editedRouteIndex = index;
      this.editedRoute = { ...editedRouteData };
      this.editedProps = this.editTourProps;
      this.showModal = true;
    },
    closeAndResetModal() {
      this.editedRouteIndex = null;
      this.showModal = false;
      this.editedRoute = {
        order: "",
        date: "",
        city: "",
        tour_type: "",
        vehicle: "",
        driver: "",
        payment_type: "",
        buy_price: "",
        buy_currency: "",
        sell_price: "",
        sell_currency: "",
        currency: "",
      };
    },
    updateRoute() {
      this.$emit("update-route", this.editedRouteIndex, this.editedRoute);
      this.closeAndResetModal();
    },
  },
};
</script>
<template>
  <b-modal
    v-model="showModal"
    title="Rota Düzenleme"
    id="modal-xl"
    size="xl"
    hide-footer
  >
    <div>
      <div class="row">
        <div class="col-12 col-md-12 mb-2">
          <h5>Rota Bilgileri</h5>
        </div>

        <div class="col-12 col-md-1">
          <div class="form-group mb-3">
            <label for="order">Sıra</label>
            <input
              type="text"
              v-model="editedRoute.order"
              class="form-control"
              id="order"
            />
          </div>
        </div>
        <div class="col-12 col-md-2">
          <div class="form-group mb-3">
            <label for="date">Tarih</label>
            <input
              type="date"
              v-model="editedRoute.date"
              class="form-control"
              id="date"
            />
          </div>
        </div>
        <div class="col-12 col-md-2">
          <div class="form-group mb-3">
            <label for="city">Şehir</label>
            <multiselect
              v-model="editedRoute.city"
              :options="editedProps.cities"
              label="city"
            ></multiselect>
          </div>
        </div>
        <div class="col-12 col-md-2">
          <div class="form-group mb-3">
            <label for="type">Tur Tipi</label>
            <multiselect
              v-model="editedRoute.tour_type"
              :options="this.types"
              label="label"
            ></multiselect>
          </div>
        </div>
        <div class="col-12 col-md-2">
          <div class="form-group mb-3">
            <label for="plate">Plaka</label>
            <multiselect
              v-model="editedRoute.vehicle"
              :options="editedProps.vehicles"
              label="plate"
              id="plate"
            ></multiselect>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="driver">Araç Sürücüsü</label>
            <multiselect
              v-model="editedRoute.driver"
              :options="editedProps.users"
              label="name"
              id="driver"
            ></multiselect>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="pay_type">Ödeme Tipi</label>
            <multiselect
              v-model="editedRoute.payment_type"
              :options="this.pay_type"
              label="label"
              id="pay_type"
            ></multiselect>
          </div>
        </div>
        <div class="col-12 col-md-2">
          <div class="form-group mb-3">
            <label for="buy_price">Alış Fiyatı</label>
            <input
              type="text"
              v-model="editedRoute.buy_price"
              class="form-control"
              id="purchase_price"
            />
          </div>
        </div>
        <div class="col-12 col-md-2">
          <div class="form-group mb-3">
            <label for="buy_currency">Alış Kur</label>
            <multiselect
              v-model="editedRoute.buy_currency"
              :options="editedProps.currencies"
              label="name"
              id="buy_currency"
            ></multiselect>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="sell_price">Satış Fiyatı</label>
            <input
              type="text"
              v-model="editedRoute.sell_price"
              class="form-control"
              id="sell_price"
            />
          </div>
        </div>
        <div class="col-12 col-md-2">
          <div class="form-group mb-3">
            <label for="sell_currency">Satış Kur</label>
            <multiselect
              v-model="editedRoute.sell_currency"
              :options="editedProps.currencies"
              label="name"
              id="sell_currency"
            ></multiselect>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-right">
          <button @click="updateRoute" class="btn btn-success">
            Güncelle
            <Icon icon="lucide:check" class="ml-2" />
          </button>
          <button @click="closeAndResetModal" class="btn btn-danger ml-2">
            İptal
            <Icon icon="lucide:x" class="ml-2" />
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style>
/* Hata alanlarını vurgulamak için kırmızı sınır ekleyin */
.error-input {
  color: red;
}
</style>
