<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import Multiselect from "vue-multiselect";
import { bankTypeEnum } from "@/components/enums.js";
import TourTable from "../../../components/reservation/TourTable";
import HotelTable from "../../../components/reservation/HotelTable";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Gider Ekle",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect, HotelTable, TourTable, },
  data() {
    return {
      title: "Gider Ekle",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Gider Listesi",
          href: "/expense"
        },
        {
          text: "Gider Ekle",
          active: true,
        },
      ],
      // Value
      type: this.$route.query.type === "reservation" ? {
        id: 1,
        name: "Rezervasyon"
      } : "",
      value: this.$route.query.id ? this.$route.query.id : "",
      balance: "",
      currency: "",
      date: "",
      bank_type: "",
      pay_type: "",
      comment: this.$route.query.type === "reservation" ? this.values?.confirme_number : "", 
      // Value End
      payType: [],
      bankTypeEnum,
      values: [],
      datascurrency: [],
      expenseLabel: this.$route.query.type === "reservation" ? "Gider: Rezervasyon" : "Gider:",
      expenseType: [],
      employee: [],
      cari: [],
      selectedData: [],
      reservation: [],
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.getReservation()
    }
    this.getAllCurrency(), this.getAllBanks(), this.getAllExpense();
  },
  watch: {
    type(value) {
      if (value.name == "Personel") {
        this.expenseLabel = "Gider: Personel"
        this.getData("/employee/getAll", "values");
      }
      if (value.name == "Acenta") {
        this.expenseLabel = "Gider: Acenta"
        this.getData("/agency/getAll", "values");
      }
      if (value.name == "Cari") {
        this.expenseLabel = "Gider: Cari"
        this.getData("/current-account/getAll", "values");
      }
    }
  },
  methods: {
    getAllCurrency() {
      this.getData("/exchange/getAll", "datascurrency");
    },
    getAllBanks() {
      this.getData("/banks/getAll", "payType");
    },
    getAllExpense() {
      this.getData("/incomeexpensetype/getAll/2", "expenseType");
    },
    getReservation() {
      const itemId = this.$route.query.id;
      const api_url = process.env.VUE_APP_BASEURL + `/reservation/${itemId}`;
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.reservation = response.data?.data
          const responseData = response.data?.data

          // Otellerin bilgileri
          const hotelDataArray = responseData.reservations.map(reservation => {
            return {
              id: reservation.hotel.id,
              type: 2, // Otel tipi
              reservation_id: responseData.id,
              name: "Otel: " + reservation.hotel.brand_name,
              confirme_number: "Confirme: " + reservation.hotel.confirme_number
            };
          });

          this.values = hotelDataArray;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    submit() {
      const api_url = process.env.VUE_APP_BASEURL + "/expense";
      const data = {
        type: this.type.id,
        expense_value: JSON.stringify(this.value),
        balance: this.balance,
        currency: this.currency.id,
        date: this.date,
        bank_type: this.bank_type.value,
        pay_type: this.pay_type.id,
        comment: this.comment,
      }
      axios
        .post(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.$router.push("/expense");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getData(endpoint, dataKey, callback = null) {
      axios
        .get(process.env.VUE_APP_BASEURL + endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this[dataKey] = response.data?.data;
          if (callback) {
            callback(response);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onChangeType() {
      if (this.bank_type.value === 4) {
        console.log(this.bank_type)
        this.selectedData = this.cari
      } else {
        const filter = this.payType.filter(item => item.type.id === this.bank_type.value);
        this.selectedData = filter;
      }
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">Gider Türü</label>
                    <multiselect v-model="type" :options="expenseType" label="name">
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">{{ expenseLabel }}</label>
                    <multiselect v-model="value" :options="values" label="name">
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">Ödeme Tarihi</label>
                    <input type="date" v-model="date" class="form-control">
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">Tutar</label>
                    <input v-model="balance" type="double" class="form-control" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">Kur</label>
                    <multiselect v-model="currency" :options="datascurrency" label="name">
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">Ödeme Türü</label>
                    <multiselect v-model="bank_type" :options="bankTypeEnum" @input="onChangeType" label="text">
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">Ödeme Yöntemi</label>
                    <multiselect v-model="pay_type" :options="selectedData" label="label">
                    </multiselect>
                  </div>
                </div>

                <div class="col-md-8">
                  <div class="form-group mb-3">
                    <label for="brand_name">Açıklama</label>
                    <input v-model="comment" type="text" class="form-control" />
                  </div>
                </div>

              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary px-4">Kaydet</button>
              </div>
            </form>
          </div>
        </div>
        <div class="mt-3" v-if="this.$route.query.type === 'reservation'">
          <aside v-if="reservation.reservations.length > 0">
            <HotelTable :reservations="reservation.reservations" :editProps="editProps" />
          </aside>

          <aside v-if="reservation.tourRoutes.length > 0">
            <TourTable :tourRoutes="reservation.tourRoutes" :editTourProps="editTourProps" />
          </aside>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped lang="scss">
#rooms {
  input {
    margin-right: 10px;
  }
}
</style>
