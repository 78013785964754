<script>
import Multiselect from "vue-multiselect";
import axios from "axios";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  props: ["reservations", "editProps", "roomsData"],
  components: {
    Multiselect,
  },
  data() {
    return {
      showModal: false,
      editedReservationIndex: null,
      editedProps: [],
      roomData: [],
      editedReservation: {
        hotel: "",
        room_id: "",
        room_count: "",
        baby_count: "",
        children_count: "",
        adult_count: "",
        confirme_number: "",
        buy_price: "",
        sell_price: "",
        buy_currency: "",
        sell_currency: "",
        first_date: "",
        last_date: "",
        board: "",
        cancelability: "",
      },
      boards: [
        { value: 1, label: "Room Only" },
        { value: 2, label: "Breakfast" },
        { value: 3, label: "Half Board" },
        { value: 4, label: "Full Board" },
      ],
      cancelabilitys: [
        { value: 1, label: "İptal Edilir" },
        { value: 2, label: "İptal Edilmez" },
      ],
    };
  },
  mounted() {
    this.editedReservation = this.reservations;
    this.$on("open-edit-modal", (reservations, rooms, hotels, currencies) => {
      this.showModal = true;
      this.editedReservation = reservations;
      this.editedProps.hotels = hotels;
      this.editedProps.currencies = currencies;
    });
  },
  methods: {
    getRooms({ id }) {
      axios
        .get(`${process.env.VUE_APP_BASEURL}/hotel/${id}/rooms`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.roomData = response.data.data;
          this.editedReservation.room = "";
        });
    },
    formatDate(date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    openModal(index, editedReservationData) {
      this.editedReservationIndex = index;
      this.editedReservation = { ...editedReservationData };
      this.editedProps = this.editProps;
      this.roomData = this.roomsData;
      this.showModal = true;
    },
    closeAndResetModal() {
      this.editedReservationIndex = null;
      this.editedProps = [];
      this.showModal = false;
      this.editedReservation = {
        hotel: "",
        room_id: "",
        room_count: "",
        baby_count: "",
        children_count: "",
        adult_count: "",
        confirme_number: "",
        buy_price: "",
        sell_price: "",
        buy_currency: "",
        sell_currency: "",
        first_date: "",
        last_date: "",
        board: "",
        cancelability: "",
        room: [],
        currencies: [],
        rooms: [],
        boards: [],
        cancelabilitys: [],
      };
    },
    updateReservation() {
      this.$emit(
        "update-reservation",
        this.editedReservationIndex,
        this.editedReservation
      );
      this.closeAndResetModal();
    },
  },
};
</script>
<template>
  <b-modal
    v-model="showModal"
    title="Otel Rezervasyon Bilgileri Düzenleme"
    id="modal-xl"
    size="xl"
    hide-footer
  >
    <div>
      <div class="row">
        <div class="col-12 col-md-12 mb-2">
          <h5>Otel Rezervasyon Bilgileri</h5>
        </div>
        <div class="col-12 col-md-12">
          <div class="form-group mb-3">
            <label for="customer_name">Otel</label>
            <multiselect
              v-model="editedReservation.hotel"
              :options="editedProps.hotels"
              label="brand_name"
              @input="getRooms"
            >
            </multiselect>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Oda Tipi</label>
            <multiselect
              v-model="editedReservation.room"
              :options="this.roomData"
              label="room_name"
            ></multiselect>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Oda Sayısı</label>
            <input
              v-model="editedReservation.room_count"
              type="text"
              class="form-control"
              id="room_count"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Giriş Tarihi</label>
            <input
              v-model="editedReservation.first_date"
              type="date"
              class="form-control"
              id="first_date"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Çıkış Tarihi</label>
            <input
              v-model="editedReservation.last_date"
              type="date"
              class="form-control"
              id="first_date"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Alış Fiyatı</label>
            <input
              v-model="editedReservation.buy_price"
              type="text"
              class="form-control"
              id="buy_price"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="kur">Alış Kur</label>
            <multiselect
              v-model="editedReservation.buy_currency"
              :options="editedProps.currencies"
              label="name"
            >
            </multiselect>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Satış Fiyatı</label>
            <input
              v-model="editedReservation.sell_price"
              type="text"
              class="form-control"
              id="sell_price"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="kur">Satış Kur</label>
            <multiselect
              v-model="editedReservation.sell_currency"
              :options="editedProps.currencies"
              label="name"
              :hover="false"
            ></multiselect>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="boards">Board</label>
            <multiselect
              v-model="editedReservation.board"
              :options="this.boards"
              label="label"
            ></multiselect>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="cancelability">İptal Edilebilirlik?</label>
            <multiselect
              v-model="editedReservation.cancelability"
              :options="this.cancelabilitys"
              label="label"
            >
            </multiselect>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Bebek</label>
            <input
              v-model="editedReservation.baby_count"
              type="text"
              class="form-control"
              id="baby_count"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Çocuk</label>
            <input
              v-model="editedReservation.children_count"
              type="text"
              class="form-control"
              id="children_count"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Yetişkin</label>
            <input
              v-model="editedReservation.adult_count"
              type="text"
              class="form-control"
              id="adult_count"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Confirme</label>
            <input
              v-model="editedReservation.confirme_number"
              type="text"
              class="form-control"
              id="confirme_number"
            />
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 text-right">
          <button @click="updateReservation" class="btn btn-success">
            Güncelle
            <Icon name="lucide:check" class="ml-2" />
          </button>
          <button @click="closeAndResetModal" class="btn btn-danger ml-2">
            İptal
            <Icon name="lucide:x" class="ml-2" />
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
