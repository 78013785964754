<script>
import HotelModal from "./HotelModal.vue";
import axios from "axios";
import { Icon } from "@iconify/vue2";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  components: {
    HotelModal,
    Icon,
  },
  props: ["reservations", "editProps"],
  data() {
    return {
      reservationsData: [],
      hotelsData: [],
      roomsData: [],
      fields: [
        { key: "hotel.brand_name", sortable: true, label: "Otel" },
        { key: "room.room_name", sortable: true, label: "Oda" },
        { key: "room_count", sortable: true, label: "Oda Sayısı" },
        { key: "adult_count", label: "Kişi Sayısı" },
        { key: "confirme_number", sortable: true, label: "Confirme" },
        { key: "buy_price", sortable: true, label: "Alış Fiyatı" },
        { key: "sell_price", sortable: true, label: "Satış Fiyatı" },
        { key: "date", label: "Giriş / Çıkış Tarihi" },
        { key: "board", sortable: true, label: "Board" },
        { key: "cancelability", sortable: true, label: "İptal Edilebilirlik" },
        { key: "actions", label: "İşlemler" },
      ],
    };
  },
  methods: {
    async openEditModal(index) {
      const reservation = this.reservations[index];

      try {
        await axios
          .get(
            `${process.env.VUE_APP_BASEURL}/hotel/${reservation.hotel.id}/rooms`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            this.roomsData = response.data?.data;
            console.log(response);
          });
        this.$refs.editModal.openModal(index, reservation);
        this.$emit("open-edit-modal", this.reservations, this.editProps);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    updateReservationInTable(index, updatedReservationData) {
      this.$set(this.reservations, index, updatedReservationData);
    },
    deleteReservation(index) {
      this.reservations[index];
      this.reservations.splice(index, 1);
    },
    calcNights(firstDate, lastDate) {
      const firstDateMoment = this.$moment(firstDate);
      const lastDateMoment = this.$moment(lastDate);
      const nights = lastDateMoment.diff(firstDateMoment, "days");
      return nights;
    },
  },
  mounted() {
    this.reservationsData = this.reservations;
  },
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Otel Listesi</h4>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table :items="reservationsData" :fields="fields" responsive="sm">
              <template v-slot:cell(adult_count)="data">
                {{ "Bebek: " + data.item.baby_count }}
                <br />
                {{ "Çocuk: " + data.item.children_count }}
                <br />
                {{ "Yetişkin: " + data.item.adult_count }}
              </template>
              <template v-slot:cell(buy_price)="data">
                {{
                  data.item.buy_price * data.item.room_count +
                  " " +
                  data.item.buy_currency.symbol
                }}
                <br />
                ({{
                  calcNights(data.item.first_date, data.item.last_date) *
                    data.item.buy_price *
                    data.item.room_count +
                  " " +
                  data.item.buy_currency.symbol
                }})
              </template>
              <template v-slot:cell(sell_price)="data">
                {{
                  data.item.sell_price * data.item.room_count +
                  " " +
                  data.item.sell_currency.symbol
                }}
                <br />
                ({{
                  calcNights(data.item.first_date, data.item.last_date) *
                    data.item.sell_price *
                    data.item.room_count +
                  " " +
                  data.item.sell_currency.symbol
                }})
              </template>
              <template v-slot:cell(date)="data">
                {{ data.item.first_date | moment("DD.MM.YYYY") }} -
                {{ data.item.last_date | moment("DD.MM.YYYY") }}
                <br />
                <!-- calc nights between dates via moment -->
                ({{
                  calcNights(data.item.first_date, data.item.last_date) +
                  " gece"
                }})
              </template>
              <template v-slot:cell(board)="data">
                {{ data.item.board.label }}
              </template>
              <template v-slot:cell(cancelability)="data">
                {{ data.item.cancelability.label }}
              </template>
              <template v-slot:cell(actions)="data">
                <div class="d-flex justify-content-between align-items-center">
                  <button
                    @click="openEditModal(data.index)"
                    class="btn btn-sm btn-secondary"
                  >
                    <Icon icon="lucide:file-edit" />
                  </button>
                  <button
                    @click="deleteReservation(data.index)"
                    class="btn btn-sm btn-danger ml-2"
                  >
                    <Icon icon="lucide:trash-2" />
                  </button>
                </div>
              </template>
            </b-table>
            <HotelModal
              ref="editModal"
              :reservations="this.reservations"
              :editProps="this.editProps"
              :roomsData="this.roomsData"
              @update-reservation="updateReservationInTable"
            />
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <!-- <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @change="onPageChange"
                  ></b-pagination> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
