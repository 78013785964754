<script>
import TourModal from "./TourModal.vue";
import axios from "axios";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  components: {
    TourModal,
  },
  props: ["tourRoutes", "editTourProps"],
  computed: {
    tableFields() {
      return [
        { key: "order", label: "Sıra" },
        { key: "date", label: "Tarih" },
        {
          key: "city",
          label: "Şehir",
          formatter: (key, value, item) => item.city.city,
        },
        {
          key: "type",
          label: "Tur Tipi",
          formatter: (key, value, item) => item.tour_type.value,
        },
        {
          key: "plate",
          label: "Plaka",
          formatter: (key, value, item) => item.vehicle.plate,
        },
        {
          key: "driver",
          label: "Araç Sürücüsü",
          formatter: (key, value, item) => item.driver.name,
        },
        {
          key: "payment_type",
          label: "Ödeme Tipi",
          formatter: (key, value, item) => item.payment_type.label,
        },
        {
          key: "buy_currency",
          label: "Alış",
          formatter: (key, value, item) =>
            item.buy_price + " " + item.buy_currency.symbol,
        },
        {
          key: "sell_currency",
          label: "Satış",
          formatter: (key, value, item) =>
            item.sell_price + " " + item.sell_currency.symbol,
        },
        { key: "actions", label: "İşlemler" },
      ];
    },
  },
  mounted() {
    console.log(this.tourRoutes);
  },
  methods: {
    formatDate(date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    openEditModal(index) {
      this.$refs.editModal.openModal(index, this.tourRoutes[index]);
      this.$emit("open-edit-modal", this.tourRoutes, this.editTourProps);
    },
    updateRouteInTable(index, updatedRouteData) {
      this.$set(this.tourRoutes, index, updatedRouteData);
    },
    deleteRoute(order) {
      const index = this.tourRoutes.findIndex((route) => route.order === order);
      if(order < 35){
        if (index !== -1) {
          this.tourRoutes.splice(index, 1);
        }
      }

      if(order > 35){
        const index = this.tourRoutes.findIndex((route) => route.order === order);
        const api_url = process.env.VUE_APP_BASEURL + "/reservation/tour/" + order;
        axios
        .delete(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.tourRoutes.splice(index, 1);
        })
        .catch((error) => {
          console.error(error);
        })
      }


    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Rota Listesi</h4>
          <b-table
            :items="this.tourRoutes"
            :fields="tableFields"
            ref="dataTable"
            hover
            class="mt-3"
          >
            <template v-slot:cell(date)="data">
              {{ formatDate(data.item.date) }}
            </template>
            <template v-slot:cell(driver)="data">
              {{ 
                data.item.sub_agency ? data.item.sub_agency_id.company_name : data.item.driver.name
              }}
            </template>
            <template v-slot:cell(plate)="data">
              {{ 
                data.item.sub_agency ? "Acenta: " : data.item.vehicle.plate
              }}
            </template>
            <template v-slot:cell(actions)="data">
              <button
                @click="openEditModal(data.index)"
                class="btn btn-sm btn-secondary"
              >
                Düzenle
              </button>
              <button
                @click="deleteRoute(data.item.id ? data.item.id : data.item.order)"
                class="btn btn-sm btn-danger ml-2"
              >
                Sil
              </button>
            </template>
          </b-table>
          <TourModal
            ref="editModal"
            :tourRoutes="tourRoutes"
            :editTourProps="editTourProps"
            @update-route="updateRouteInTable"
          />
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <!-- <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="onPageChange"></b-pagination> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
